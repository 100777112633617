import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-simple-pricing',
  templateUrl: './simple-pricing.component.html',
  styleUrls: ['./simple-pricing.component.css']
})
export class SimplePricingComponent implements OnInit{
  
  @Input() simple_pricingData: Array<{
    warning?: string,
    title: string,
    price: number,
    list?: Array<[]>,
    btn?: string,
  }>;
  
  constructor(
    private router: Router,
  ) { }
  
  ngOnInit(): void {
      let txt_js = `
        try{
          const mp =  new MercadoPago('APP_USR-c35aeef0-f71d-4447-9d58-e91cc6b1ed47',{
            locale: 'es-CL'
          });
          mp.checkout({
            preference: {
              id: '185732511-7822ba12-4217-4b1e-9938-2e2625b38e22'
            },
            render: {
              container: 'app-root app-master-page app-page-pricing .pay_0',
              label: 'Comprar',
            }
          });

          /*mp.checkout({
            preference: {
              id: '185732511-953cc142-c932-437a-b3fb-6697fe925a76'
            },
            render: {
              container: 'app-root app-master-page app-page-pricing .pay_1',
              label: 'Comprar',
            }
          });

          mp.checkout({
            preference: {
              id: '185732511-4e77cbce-2ee8-4350-9b12-8910c99297a7'
            },
            render: {
              container: 'app-root app-master-page app-page-pricing .pay_2',
              label: 'Comprar',
            }
          });*/
        }catch(error){
        }`
      
      let txt_js_paypal = `
      initPayPalButton();
      function initPayPalButton() {
        try{
          setTimeout(() => { 
            paypal.Buttons({
              style: {
                shape: 'pill',
                color: 'blue',
                layout: 'vertical',
                label: 'paypal',
                
              },
      
              createOrder: function(data, actions) {
                return actions.order.create({
                  purchase_units: [{"description":"TaleWeaverIA - Basic Credits","amount":{"currency_code":"USD","value":5}}]
                });
              },
              
              onApprove: function(data, actions) {
                return actions.order.capture().then(function(order) {
                  
                  // Full available details
                  // Show a success message within this page, e.g.
                  const element = document.getElementById('paypal-button-container');
                  element.innerHTML = '';
                  element.innerHTML = '<h3>Thank you for your payment!</h3>';

                  let id = order["id"]
                  let name = order["payer"]["name"]["given_name"]+" "+order["payer"]["name"]["surname"]
                  let description = order["purchase_units"][0]["description"]
                  let status = order["status"]
                  let pay_date = order["update_time"]
                  let cost = order["purchase_units"][0]["payments"]["captures"][0]["amount"]["value"]
                  let total_cost = order["purchase_units"][0]["payments"]["captures"][0]["amount"]["value"]
                  let params = '?id='+id+'&name='+name+'&status='+status+'&date_created='+pay_date+'&description='+description+'&cost='+cost+'&total_cost='+total_cost

                  window.location.href = 'https://condonacioncae.cl/page-invoice/'+ params;
                  // Or go to another URL:  actions.redirect('thank_you.html');
                  
                });
              },
      
              onError: function(err) {
              }
            }).render('#paypal-button-container');
          }, 1000);
        }catch(error){
        }
      }
      `

      let script_btn_mercadopago = document.createElement('script');
      let script_btn_paypal = document.createElement('script');
      let canvas_btn_mp = document.getElementById('script_btn_mercadopago')
      let canvas_btn_paypal = document.getElementById('script_btn_paypal')
      script_btn_mercadopago.text = txt_js
      script_btn_paypal.text = txt_js_paypal
      //canvas_btn_mp.appendChild(script_btn_mercadopago);
      canvas_btn_paypal.appendChild(script_btn_paypal);
   

  } 

}

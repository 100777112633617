<section class="bg-half-170 bg-light d-table w-100" id="home" *ngIf="!isSelectionChileHistory">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <div class="storycard" (click)="goTo('free')">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/story-free.jpg');"></div>
              <div class="storycard-title">Historia Libre</div>
              <div class="storycard-text">¡Comenzar!</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="storycard" (click)="chileHistory()">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/story-history.jpg');"></div>
              <div class="storycard-title">Historia de Chile</div>
              <div class="storycard-text">¡Comenzar!</div>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</section>

<section class="bg-half-170 bg-light d-table w-100" *ngIf="isSelectionChileHistory">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3">
        <div class="storycard" (click)="goTo('history', 'chile-independency')">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/independency-chile.jpg');"></div>
              <div class="storycard-title">Independencia de Chile</div>
              <div class="storycard-text">¡Entrar!</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="storycard disabled">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/pacific-war.jpg');"></div>
              <div class="storycard-title">Guerra del Pacifico</div>
              <div class="storycard-text">Pronto...</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="storycard disabled">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/revolution-1891.jpg');"></div>
              <div class="storycard-title">Revolución de 1891</div>
              <div class="storycard-text">Pronto...</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="storycard disabled">
          <div class="storycard-content">
              <div class="storycard-image" style="background-image: url('assets/images/story/santiago-conquest.jpg');"></div>
              <div class="storycard-title">Conquista y Fundación de Santiago (1540-1541)</div>
              <div class="storycard-text">Pronto...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <!--<a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/escudo-colegio.png" class="l-dark logo-light-mode" height="150" alt="" style="position:fixed">
      </a>-->

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>


      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/escudo-colegio.png" class="l-dark" height="150" alt="" style="position: fixed;">
        <img src="assets/images/escudo-colegio.png" class="l-light" height="150" alt=""  style="position: fixed;">
      </a>
    </div>

    <!--Login button Start-->
    <ul class="buy-button list-inline mb-0">
      <app-credits [totalCredits]="totalCredits"></app-credits>
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" 
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          (click)="openright(content)">
        </a>
      </li>
    </ul>
    <!--Login button End-->

    <ul class="buy-button list-inline mb-0 app-header" *ngIf="appicons == true">
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" class="btn btn-icon btn-light">
          <img src="assets/images/app/app-store.png" class="avatar avatar-ex-small p-1" alt="">
        </a>
      </li>

      <li class="list-inline-item mb-0 ps-2">
        <a href="javascript:void(0)" class="btn btn-icon btn-light">
          <img src="assets/images/app/play-store.png" class="avatar avatar-ex-small p-1" alt="">
        </a>
      </li>
    </ul>

    <ul class="buy-button list-inline mb-0" *ngIf="Nfticons == true">
      <li class="list-inline-item mb-0">
        <div class="dropdown">
          <button type="button" class="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <!-- <i class="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                  <i class="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i> -->
            <i class="uil uil-search text-dark fs-5 align-middle"></i>
          </button>
          <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
            style="width: 300px;">
            <div class="search-bar">
              <div id="itemSearch" class="menu-search mb-0">
                <form role="search" method="get" id="searchItemform" class="searchform">
                  <input type="text" class="form-control border rounded" name="s" id="searchItem"
                    placeholder="Search...">
                  <input type="submit" id="searchItemsubmit" value="Search">
                </form>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul><!--end login button-->

    <div id="navigation" >
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Inicio</a></li>
        <!--<li><a routerLink="/story-builder" class="nav-link-ref">Creador de Historias</a></li>-->
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div>
      <!--end login button-->

    </div>
    <!--end navigation-->


  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header p-4 border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img src="assets/images/logo.png" height="50" class="light-version" alt="">
      <img src="assets/images/logo-white.png" height="50" class="dark-version" alt="">
    </h5>
    <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
      aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i></button>
  </div>
  <div class="offcanvas-body p-4">
    <div class="row">
      <div class="col-12">
        <img  src="{{userData.photoURL}}" class="img-fluid d-block mx-auto rounded-full" style="max-width: 256px;" alt="">
        <img  src="assets/images/contact.svg" class="img-fluid d-block mx-auto" style="max-width: 256px;" alt="">
        <div class="card border-0 mt-5" style="z-index: 1">
          <div class="card-body p-0">
            <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              
              <div class="row" >
                <div class="col-md-12">
                  <div class="mb-12">
                    <label class="form-label">Nombre</label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" disabled value="{{ userData.displayName }}">
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="mb-12">
                    <label class="form-label">Email</label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" value="{{ userData.email }}" disabled>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row" >
                <div class="col-12 mt-md-5">
                  <div class="d-grid">
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-12 mt-md-5">
                  <div class="d-grid">
                    <button routerLink="/account-profile" class="btn btn-info">Ver Perfil</button>
                  </div>
                  <br>
                  <div class="d-grid">
                    <button (click)="logout()" class="btn btn-primary">Cerrar Sesión</button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer p-4 border-top text-center">
    <ul class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1">
     <!--<li class="list-inline-item mb-0"><a href="https://dribbble.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.behance.net/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-behance align-middle" title="behance"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.facebook.com/shreethemes" target="_blank"
          class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>-->
      <li class="list-inline-item mb-0"><a href="https://www.instagram.com/iflabcl/" target="_blank"
          class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
      <!--<li class="list-inline-item mb-0"><a href="https://twitter.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-twitter align-middle" title="twitter"></i></a></li>
      <li class="list-inline-item mb-0"><a href="mailto:support@shreethemes.in" class="rounded"><i
            class="uil uil-envelope align-middle" title="email"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://shreethemes.in" target="_blank" class="rounded"><i
            class="uil uil-globe align-middle" title="website"></i></a></li>-->
    </ul><!--end icon-->
  </div>
</ng-template>
<!-- Offcanvas End -->
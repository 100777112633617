import { AfterContentInit, AfterViewInit, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoryService } from 'src/app/shared/services/story.service';
import { gameStarted } from './interfaces';
import { UserService } from 'src/app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-story-history',
  templateUrl: './story-history.component.html',
  styleUrls: ['./story-history.component.css'],
})
export class StoryHistoryComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Output() activeBookEmmit = new EventEmitter<boolean>;


  public pricing: string = '64696917f39fd65bf342258e';
  public tmp_history  = "66c67adcc4db25674e5a8752";
  public gameStarted: gameStarted;
  public users: any;
  public acthors: any;
  public milestones: any;
  public teamForm: FormGroup;
  public story: {} = null
  public isLoadingGroups: boolean = false;
  public isLoadingMilestones: boolean = false;
  public showEvaluation: boolean = false
  questionsEvaluation: Array<any> = []
  isEvaluationDone: boolean = false
  evaluationResults: {} = {}


  constructor(
    private router: Router,
    private _modalService: NgbModal,
    private _storyService: StoryService,
    private _userService: UserService,
    private fb: FormBuilder,
  ){
    //this.option = this.router.getCurrentNavigation()?.extras.state?.data;
  }

  ngOnInit(): void {
    this.getStartedGame()
  }

  checkMilestone(milestoneId: number): boolean {
    //return this.gameStarted.campaign.current_milestone === milestoneId || milestoneId < this.gameStarted.campaign.current_milestone;
    return  milestoneId < this.gameStarted.campaign.current_milestone;
  }

  isMilestoneCompleted(milestoneId: number): boolean {
    return milestoneId < this.gameStarted.campaign.current_milestone;
  }

  getMilestoneText(milestoneId: number): string {
    if (milestoneId === this.gameStarted.campaign.current_milestone) {
      return '¡Iniciar Hito!';
    } else if (this.isMilestoneCompleted(milestoneId)) {
      return 'Completado';
    } else {
      return '- Bloqueado -';
    }
  }
  

  ngAfterViewInit(): void {}

  ngAfterContentInit(): void {}


  getMilestones(): void{
    this._storyService.getMilestones(this.gameStarted.history._id).subscribe(
      response => 
        {
          this.milestones = response.data || []

        })
  }

  getStartedGame(): void{
    this.isLoadingGroups = true
    this._storyService.startGame().subscribe(
      response => 
        {
          this.isLoadingGroups = false
          this.gameStarted = response.data || null
          if (this.gameStarted.campaign){
            this.teamForm = this.fb.group({
              team:    [{}, Validators.required],
              acthor:  ['', Validators.required]
            })

            this.getMilestones()
          }
        })
        
  }

  startCampaign():void {
    let sendData = {
      history: this.gameStarted.history._id
    }
    this._storyService.startCampaing(sendData).subscribe(
      response => 
        {
          this.gameStarted = response.data || null
        }
    )
  }

  saveTeam(): void{
    let sendData = {
      campaign: this.gameStarted.campaign._id,
      team: this.teamForm.get("team").value,
      acthor: this.teamForm.get("acthor").value
    }
    console.log(sendData)
    this._storyService.saveTeam(sendData).subscribe(
      response => 
        {

        }
    )
  }


  travelToPast(){
    let sendData = {
      campaign: this.gameStarted.campaign._id,
    }
    this._storyService.travelPast(sendData).subscribe(
      response => 
        {
          this.gameStarted = response.data || null
        }
    )
  }


  startMilestone(modalBook, milestone): void {
    let sendData = {
      pricing:  this.pricing,
      campaign: this.gameStarted.campaign._id
    }
    this.isLoadingMilestones = true
    this._storyService.historyStart(sendData).subscribe(
      response => 
        {
          this.isLoadingMilestones = false
          this.story = {
            _id: response.data.story._id,
            title: milestone.name,
            content: response.data.story.content || '',
            chapter: milestone.chapter
          }

          this.gameStarted.campaign = response.data.campaign

          this._modalService.open(modalBook, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
             // this.showEvaluation = false
            },
            (reason) => {
              //this.showEvaluation = false
            },
          );
        }
    )
  }


  readMilestoneStory(modalBook, milestone): void {
    let milestone_id = milestone._id
    this._storyService.readMilestoneStory(this.gameStarted.campaign._id, milestone_id).subscribe(
      response => 
        {
          this.story = {
            _id: response.data._id,
            title: milestone.name,
            content: response.data.content || '',
            chapter: milestone.chapter
          }
          this._modalService.open(modalBook, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
              /*if(!this.showEvaluation){
                this.story = null
              }*/
            },
            (reason) => {
              /*if(!this.showEvaluation){
                this.story = null
              }*/
            },
          );
        })
  }

  createTest(){
    let sendData = {
      story: this.story
    }
    this._storyService.createTest(sendData).subscribe(test =>{
      this.questionsEvaluation = test.data
      //this.showBook = false
      this.showEvaluation = true
    })
  }

  closeBook(): void{
    this.activeBookEmmit.emit(false)
    this.questionsEvaluation = null
    this.evaluationResults = null
    this.isEvaluationDone = false
    this.showEvaluation = false
    this.story = null
  }

  evaluationDone(evaluationResults): void {
    this.evaluationResults = evaluationResults
    this.isEvaluationDone = true
  }
}

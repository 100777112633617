import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'cae-calc',
  templateUrl: './cae-calc.component.html',
  styleUrls: ['./cae-calc.component.css']
})
export class CaeCalcComponent implements OnInit{
  public loadingUf: boolean = true;

  condonationForm!: FormGroup;
  calculatedAmount: string = ''; // Almacena el resultado del cálculo dinámico

  constructor(
    private fb: FormBuilder,
    private userService: UserService){}

  ngOnInit(): void {
    this.condonationForm = this.fb.group({
      cuotasPagadas: ['', [Validators.required, Validators.min(0)]],
      cuotasTotales: ['', [Validators.required, Validators.min(1)]],
      valorUf: ['', [Validators.required, Validators.min(0)]],
      egresado: [false, Validators.required], // Switcher: ¿Terminó su carrera?
      alDia: [false, Validators.required],   // Switcher: ¿Está al día con pagos?
    });
    
    this.getCurrencyValues()

     // Suscribirse a los cambios del formulario
     this.condonationForm.valueChanges.subscribe(() => {
      this.calculateCondonationAmount(); // Recalcular cada vez que cambien los valores
    });
  }

  calculateCondonationAmount(): void {
    const cuotasPagadas = this.condonationForm.get('cuotasPagadas')?.value || 0;
    const cuotasTotales = this.condonationForm.get('cuotasTotales')?.value || 1;
    const valorUf = this.condonationForm.get('valorUf')?.value || 0;
    const egresado = this.condonationForm.get('egresado')?.value || false;
    const alDia = this.condonationForm.get('alDia')?.value || false;

    if (cuotasPagadas <= 0 || cuotasTotales <= 0 || valorUf <= 0) {
      this.calculatedAmount = ''; // Si faltan datos, el monto es 0
      return;
    }

    let ufMultiplier = 0;

    // Determinar el multiplicador de UF basado en las condiciones
    if (!egresado && alDia) {
      ufMultiplier = 60; // No terminó y está al día
    } else if (!egresado && !alDia) {
      ufMultiplier = 30; // No terminó y está en mora
    } else if (egresado && alDia) {
      ufMultiplier = 40; // Terminó y está al día
    } else if (egresado && !alDia) {
      ufMultiplier = 20; // Terminó y está en mora
    }

    // Calcular la condonación
    const factor = (cuotasPagadas / cuotasTotales) + 1;
    this.calculatedAmount = `$ ${Math.round(factor * (ufMultiplier * valorUf)).toLocaleString('es-CL')}`;
  }

  getCurrencyValues() {
    this.userService.miIndicadorIntegration().subscribe(
      (result) => {
        this.loadingUf = false;
        const uf = result.data.uf.valor
        this.condonationForm.get('valorUf').setValue(uf)
      }
  )}

}

<!-- Switcher Start -->
<a  href="javascript:void(0)" class="switcher-btn shadow-md text-primary bg-white" data-bs-toggle="offcanvas"
    data-bs-target="#switcher-sidebar" aria-controls="offcanvasLeft" (click)="openLeft(content)">
    <img src="assets/images/book/icon-ia-book.png" width="50px"/>
</a>

<ng-template #content let-offcanvas >
    <div class="offcanvas-header p-4 border-bottom main-bg">
        <img _ngcontent-bwy-c145="" src="assets/images/book/icon-ia-book.png" height="100">
        <button type="button" class="btn-close d-flex align-items-center text-dark" style="color:gray" data-bs-dismiss="offcanvas"
            aria-label="Close" (click)="offcanvas.dismiss('Cross click')">
            <i class="uil uil-times fs-4"></i>
          </button>
    </div>
    <div class="offcanvas-body p-4 card rounded shadow main-bg" >
        <div class="row">
            <div class="col-12">
                <div class="text-center">
                  <h6 class="fw-bold title">Mis Historias</h6>
                  <div *ngIf="stories.length < 1">
                    <p>Aun no tienes ninguna Historia</p>
                    <p>¡Que esperas para crear tu primera  y emocionante Historia!</p>
                  </div>
                    <div class="widget mt-4">
                      <ul *ngFor="let story of stories; let i = index"  class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                        <li class="navbar-item account-menu px-0">
                          <a href="javascript:void(0)"  (click)="openStory(contentStory, story)" class="btn navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                            <span class="h4 mb-0"><i class="uil uil-comment-dots"></i></span>
                            <h6 class="mb-0 ms-2">{{ story.title }}</h6>
                          </a>
                        </li>
          
                      </ul>
                    </div>

                    <ul class="pagination mb-0" *ngIf="stories.length > 0">
                      <li (click)="getStories(currentPage-1)" class="page-item" ><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                      <li *ngFor="let page of pages" class="page-item pagination-page">
                        <a (click)="getStories(page)" class="page-link" href="javascript:void(0)" >{{page}}</a>
                      </li>
                      <li class="page-item" (click)="getStories(currentPage+1)" ><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
                   </ul>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-book  [storyText]="story_text" *ngIf="openBook" (activeBookEmmit)="onActiveBook()"></app-book>

<ng-template #contentStory  let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ story_text.title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form>
      <div class="mb-3">
        <label >Capitulo 1</label>
        <div class="input-group" [innerHTML]="modalStoryText"></div>
      </div>
      </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Cerrar</button>
    <button type="button" class="btn btn-primary" disabled>Proximamente Continuar Historia</button>

  </div>
</ng-template>


  <!-- Wishlist Popup Start -->
  <ng-template #contentConfirmationStory  let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ story_title }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/shop/cart.png" class="avatar avatar-medium rounded-circle shadow" alt="">
        <div class="mt-4">
          <h4>¿Estás seguro que quieres continuar esta historia?</h4>
          <p class="text-muted">
              Ha sido una historia interesante, debes tener en cuenta que si quieres continuar esta historia, tienes que tener créditos disponibles ya que la continuacion te consumira 1 crédito</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="continueStory()">SI</button>
      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">NO</button>
    </div>
  </ng-template>

  <ng-template #continueStory  let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ story_title }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/shop/cart.png" class="avatar avatar-medium rounded-circle shadow" alt="">
        <div class="mt-4">
          <h4>¿Estás seguro que quieres continuar esta historia?</h4>
          <p class="text-muted">
              Ha sido una historia interesante, debes tener en cuenta que si quieres continuar esta historia, tienes que tener créditos disponibles ya que la continuacion te consumira 1 crédito</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="continueStory()">SI</button>
      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">NO</button>
    </div>
  </ng-template>
<!-- Switcher End -->
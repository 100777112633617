<section class="bg-light d-table w-100 mb-4" id="cae-calc">
    <div class="container">
      <div class="row mt-4">
        <form [formGroup]="condonationForm">
          <!-- Switchers -->
          <div class="form-check form-switch mb-3">
            <input class="form-check-input" type="checkbox" id="finishedCareerSwitch" formControlName="egresado">
            <label class="form-check-label" for="finishedCareerSwitch">Terminé mi carrera</label>
          </div>
        
          <div class="form-check form-switch mb-3">
            <input class="form-check-input" type="checkbox" id="onTimeSwitch" formControlName="alDia">
            <label class="form-check-label" for="onTimeSwitch">Estoy al día con mis pagos</label>
          </div>
        
          <!-- Inputs -->
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Cuotas Pagadas <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i-feather name="check-circle" class="fea icon-sm icons"></i-feather>
                  <input formControlName="cuotasPagadas" id="c_paid" type="number" class="form-control ps-5">
                  <div *ngIf="condonationForm.get('cuotasPagadas')?.invalid && condonationForm.get('cuotasPagadas')?.touched" class="text-danger">
                    Este campo es obligatorio y debe ser un número válido.
                  </div>
                </div>
              </div>
            </div>
        
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Cuotas Totales <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i-feather name="credit-card" class="fea icon-sm icons"></i-feather>
                  <input formControlName="cuotasTotales" id="c_total" type="number" class="form-control ps-5">
                  <div *ngIf="condonationForm.get('cuotasTotales')?.invalid && condonationForm.get('cuotasTotales')?.touched" class="text-danger">
                    Este campo es obligatorio y debe ser un número válido.
                  </div>
                </div>
              </div>
            </div>
        
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label">Valor UF <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i-feather name="dollar-sign" class="fea icon-sm icons"></i-feather>
                  <input
                    formControlName="valorUf"
                    id="uf"
                    type="number"
                    class="form-control ps-5"
                    placeholder="Obteniendo valor UF..."
                    [readonly]="loadingUf"
                  />
                  <div *ngIf="condonationForm.get('valorUf')?.invalid && condonationForm.get('valorUf')?.touched" class="text-danger">
                    Este campo es obligatorio y debe ser un número válido.
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <!-- Resultado -->
          <div class="col-md-12 text-center mt-2 mb-2">
            <div class="mb-3">
              <span class="badge rebadge rounded-pill bg-soft-info d-flex flex-column align-items-center justify-content-center">
                <span class="fw-bold">Monto Condonación</span>
                <span *ngIf="calculatedAmount != '' " class="condonation-amount mt-4">{{ calculatedAmount }}</span>
              </span>
            </div>
          </div>

        </form>
      </div>
  </div>
</section>
  
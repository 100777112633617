import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, Output, ChangeDetectorRef, ElementRef, SimpleChanges, OnChanges, ViewChildren, QueryList, AfterViewInit, Input } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { StoryService } from '../services/story.service';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '../loading/loading.service';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css']
})

/**
 * Switcher component
 */
export class SwitcherComponent implements  OnInit, OnChanges {
  @Input('storyData') storyData : any = '';

  // set variable
  isVisible: boolean;
  stories: Array<any> = [];
  closeResult = '';
  story_title = '';
  story_text = {};
  canGenerateStory: boolean = false;
  pricings: Array<{}> = [];
  openBook: boolean = false
  testText1 = {title: ' hola que haces', pages: []}
  testText2 = {title: ' hola que haces 2', pages: []}
  modalStoryText = ''
  pages = [];
  currentPage = 1;
  offcanvasRef : any;
  minTotalPages = 1
  totalPages = 100000


  constructor(
    private offcanvasService: NgbOffcanvas,
    private _storyService: StoryService,
    private modalService: NgbModal,
    private _auth: AuthService,
    private _loadingService: LoadingService) { }

  
  ngOnChanges() : void {
    if (this.storyData){
      this.openStory(null, this.storyData)
    }
    console.log('cambio storyData:', this.storyData)
  }
    
  ngOnInit(): void {
    // assign value
    console.log('storyData')
    console.log(this.storyData)
    this.isVisible = false;
    this._auth.check().subscribe((status: any) =>
    {
      console.log(status)
      if(status){
        this.getStories(1)
        this._loadingService.hide()
      }else{
        this._auth.signOut()
      }
    })
  }

  getStories(page: number){
    if (page <= this.totalPages && page >= this.minTotalPages){
      this._storyService.getStories(page).subscribe((stories: any) => {
        this.stories = stories.data.data
        this.totalPages = stories.data.pages
        this.pages = Array.from({ length: stories.data.pages }, (_, i) => i + 1);
        setTimeout(() => {
          const pagination = document.querySelectorAll('.pagination-page')
          pagination.forEach((item, i) => 
            {
              if(item.classList.contains('active')){
                item.classList.remove('active')
              }
              if( page === i+1 ){
                this.currentPage = page
                item.classList.add('active')
              }
            })
        }, 300);
        this._loadingService.hide()
      })
    }
  }

  /**
   * Change theme color
   */
  onChangeColor(color: string) {
    document
      .getElementById('color-opt')
      .setAttribute('href', './assets/css/colors/' + color + '.css');
  }

  /**
   * Set dark theme
   */
  setDark() {
    document.getElementById('theme-opt').setAttribute('href', './assets/css/style-dark.min.css');
  }

  /**
   * Set light theme
   */
  setLight() {
    (document.querySelector('.t-rtl-dark') as HTMLElement).style.display = "none";
    (document.querySelector('.t-rtl-light') as HTMLElement).style.display = "inline-block";    
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap-rtl.min.css');

  }

  /**
   * Set dark-rtl theme
   */
  darkRtl() {
    document.documentElement.dir = "rtl";
    (document.querySelector('.t-rtl-dark') as HTMLElement).style.display = "none";
    (document.querySelector('.t-ltr-dark') as HTMLElement).style.display = "inline-block";    
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap-dark-rtl.min.css');
    document.querySelector('.theme-opt-style').setAttribute('href', './assets/css/style-dark-rtl.min.css');
  }
  /**
   * Set dark-light theme
   */
  darkLtr() {   
    (document.querySelector('.t-dark') as HTMLElement).style.display = "none";
    (document.querySelector('.t-light') as HTMLElement).style.display = "inline-block";
    (document.querySelector('.t-rtl-dark') as HTMLElement).style.display = "inline-block";
    (document.querySelector('.t-rtl-light') as HTMLElement).style.display = "none";
    (document.querySelector('.t-ltr-dark') as HTMLElement).style.display = "none";    
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap-dark.min.css');
    document.querySelector('.theme-opt-style').setAttribute('href', './assets/css/style-dark.min.css');
    document.documentElement.dir = "ltr";
  }

  /**
   * Set dark-light theme
   */
  lightLtr() {  
    (document.querySelector('.t-dark') as HTMLElement).style.display = "inline-block";
    (document.querySelector('.t-light') as HTMLElement).style.display = "none";
    (document.querySelector('.t-rtl-dark') as HTMLElement).style.display = "none";
    (document.querySelector('.t-rtl-light') as HTMLElement).style.display = "inline-block";
    (document.querySelector('.t-ltr-dark') as HTMLElement).style.display = "none";  
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap.min.css');
    document.querySelector('.theme-opt-style').setAttribute('href', './assets/css/style.min.css');
    document.documentElement.dir = "ltr";
  }
  /**
   * Set rtl theme
   */
  setRtl() {
    document.documentElement.dir = "rtl";
    (document.querySelector('.t-rtl-light') as HTMLElement).style.display = "none";
    (document.querySelector('.t-ltr-light') as HTMLElement).style.display = "inline-block";    
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap-rtl.min.css');
    document.querySelector('.theme-opt-style').setAttribute('href', './assets/css/style-rtl.min.css');
  }
  /**
   * Set light theme
   */
  setLtr() {
    document.documentElement.dir = "ltr";
    (document.querySelector('.t-ltr-light') as HTMLElement).style.display = "none";
    (document.querySelector('.t-rtl-light') as HTMLElement).style.display = "inline-block";    
    document.querySelector('.theme-opt').setAttribute('href', './assets/css/bootstrap.min.css');
    document.querySelector('.theme-opt-style').setAttribute('href', './assets/css/style.min.css');
  }

  /**
   * Toggle switcher
   */
  onChangeSwitch() {
    this.isVisible = !this.isVisible;
  }


  // Demo Offcanvas
  openLeft(content: TemplateRef<any>) { 
    this.offcanvasRef = this.offcanvasService.open(content, { position: 'start'});
    if (!this.offcanvasService.activeInstance.observed){
      this.getStories(this.currentPage)
    }
  }

 
  openStory(content, data){
    console.log('pase')
   // if ( window.innerWidth > 1000){
      this.bookStyle(data)
    //}else{
    //  this.modalStyle(content, data)    
    //}
  }

  bookStyle(data){
    this.openBook = false;
    this.story_text = data

    setTimeout(() => {
      this.openBook = true;
    }, 500);
    
  }

  modalStyle(content, data){
    this.modalStoryText = data.content
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  
  confirmationContinue(modal){
    this.modalService.open(modal, { size: 'sl', ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  continueStory(){
    this._loadingService.show()
    this._storyService.getCredits().subscribe((result: any) =>{
      if (result.data.length > 0){
          result.data.map((x: any) => {
            if (x.credits > 0){
              this.pricings.push(x)
              this.canGenerateStory = true
            }
          })
      }
    })
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  onActiveBook(){
    this.openBook = false
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-page-blog-detail',
  templateUrl: './page-blog-detail.component.html',
  styleUrls: ['./page-blog-detail.component.css']
})

/**
 * Page Blog-Detail Component
 */
export class PageBlogDetailComponent implements OnInit {

      // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  isMobile: boolean = false;
  loading: boolean = false;
  commentForm: FormGroup;
  comments: any;

  
  constructor(
    private userService: UserService,
    private fb : FormBuilder,
    private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.checkScreenSize(); 
    this.commentForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      description: ['', [Validators.required]],
      recaptcha: ['', Validators.required]
    });
    this.getComments();
  }

  onResolveCaptcha(action: string) {
   

    this.reCaptchaV3Service.execute(action).subscribe((token: string) => {
      this.commentForm.get('recaptcha')?.setValue(token); 
      if (this.commentForm.invalid) {
        console.log('Formulario inválido.');
        return;
      }
      this.createComment();
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize(); // Detecta cambios en el tamaño de la ventana
  }

  private checkScreenSize(): void {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }

  getComments() {
    this.loading = true;
    this.userService.getComments().subscribe(
      (result) => {
        this.loading = false;
        this.comments = result.data
      }
  )}

  createComment() {
    const payload = {
      name: this.commentForm.get('name').value,
      email: this.commentForm.get('email').value,
      description: this.commentForm.get('description').value,
      recaptcha: this.commentForm.get('recaptcha').value
    }
    this.loading = true;
    this.userService.createComment(payload).subscribe(
      (result) => {
        this.loading = false;
        this.getComments()
      }
  )}

  cleanInputs() {
    this.commentForm.get('name').setValue("");
    this.commentForm.get('email').setValue("");
    this.commentForm.get('description').setValue("");
    this.commentForm.get('recaptcha').setValue("");

    this.commentForm.updateValueAndValidity()
  }

  formatDate(date): string {
    const isoDate = date;
    return format(new Date(isoDate), "do 'de' MMMM, yyyy 'a las' hh:mm a", { locale: es });
  }

}

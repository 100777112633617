<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 bg-light" id="home">
  <div class="container">
    <div class="row mt-5 mt-sm-0 align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3">¡Despierta tu imaginación y crea historias <span class="text-primary">Asombrosas!</span> </h1>
          <p class="para-desc text-muted">¡El momento de crear tu propio legado literario es ahora! No importa si eres un escritor novel o un autor experimentado,
            nuestra plataforma está diseñada para inspirar y apoyar a todos los amantes de la escritura.</p>
            <p class="para-desc text-muted">
              Tienes 1 crédito para experimentar!
            </p>
          <div class="mt-4 pt-2">
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="p-4 rounded-md shadow">
          <img src="assets/images/index-header-2-min.jpg" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="bg-light p-5 rounded-md shadow me-lg-5">
          <img src="assets/images/index-header-min.jpg" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">¿ Cómo funciona ?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Es muy sencillo <span
            class="text-primary fw-bold">TaleWeaver</span> te proveerá de los pasos necesarios para generar tu historia, son muy simples!.</p>
          <ul class="list-unstyled text-muted my-4">
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
              class="uil uil-check-circle align-middle"></i></span>Tienes 1 crédito gratis!</li><br>
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>¡Recarga creditos si no tienes!</li><br>
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Si no tienes créditos, haz una recarga</li><br>
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Configura parametros de tu historia</li><br>
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Presiona Generar Historia</li><br>
            <li class="list-inline-item me-lg-5 me-4"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Listo disfruta de tu creación!</li>
          </ul>
          <!--<img src="assets/images/book/sign.png" class="img-fluid" alt="">
          <div class="d-flex align-items-center mt-4">
            <img src="assets/images/client/05.jpg" class="avatar avatar-md-md rounded-circle shadow-lg" alt="">
            <div class="flex-1 content ms-3">
              <h5 class="mb-0">Cristina Murphy</h5>
              <p class="text-muted mb-0">Auther</p>
            </div>
          </div>-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-user d-block rounded h3 mb-0"></i>
          </div>
          <div class="content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Incentiva Lectura</a></h5>
            <p class="text-muted mb-0">Creemos que es una buena instancia para potenciar la lectura. Ya sea que te apasione la fantasía épica,
              la ciencia ficción futurista, los cuentos de hadas o el misterio intrigante,nuestra plataforma te ofrece una amplia gama de géneros y
              estilos para explorar y experimentar.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-usd-circle d-block rounded h3 mb-0"></i>
          </div>
          <div class="content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Precio</a></h5>
            <p class="text-muted mb-0">El costo de procesamiento es alto por lo cual, para solventar este costo solicitamos una pequeña cantidad para mantener esto vivo.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="d-flex features feature-clean feature-primary">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-star d-block rounded h3 mb-0"></i>
          </div>
          <div class="content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Contenido Innovador</a></h5>
            <p class="text-muted mb-0">¿Alguna vez has soñado con escribir un cuento que transporte a los lectores a mundos mágicos y personajes fascinantes? 
              ¡Ahora es tu oportunidad de hacerlo realidad con nuestra innovadora plataforma de escritura de cuentos!.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid mt-100 mt-60">
    <div class="rounded py-5 bg-light shadow">
      <div class="container my-lg-5">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Recargas Disponibles</h4>
              <p class="text-muted para-desc mx-auto mb-0">Para generar tu Historia necesitaras <span
                  class="text-primary fw-bold">creditos</span>, actualmente existen 3 tipos de recargas, selecciona el que mas te acomode.</p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="row">
              <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate text-center shadow border-0 rounded">
                  <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                      class="text-center d-block shadow small h6">Mejor</span></div>
                  <div class="card-body">
                    <h5 class="title mb-4">Básico</h5>
                    <h2 class="fw-bold">$ 5</h2>

                    <p class="text-muted mb-0">10 créditos, explora lo que puedes hacer con esta increible herramienta.</p>
                    <div class="d-grid">
                      <button class="btn btn-primary" (click)="goPricing()">Ir</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card pricing pricing-primary business-rate starter-plan text-center shadow border-0 rounded">
                  <div class="card-body">
                    <h5 class="title text-primary mb-4">Avanzado</h5>
                    <h2 class="fw-bold">$ 15</h2>

                    <p class="text-muted mb-0">30 créditos, para quienes quieran historias mas extensas..</p>
                    <div class="d-grid">
                      <button class="btn btn-primary" (click)="goPricing()">Ir</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-4 col-12 mt-4 pt-2">
                <div class="card pricing-rates business-rate text-center shadow border-0 rounded">
                  <div class="card-body">
                    <h5 class="title mb-4">Profesional</h5>
                    <h2 class="fw-bold">$ 25</h2>

                    <p class="text-muted mb-0">50 créditos, la recarga mas extensa, sumergete en todas las posibilidades.</p>
                    <div class="d-grid">
                      <button class="btn btn-primary" (click)="goPricing()">Ir</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end div-->
  </div>
  <!--end container fluid-->

  <!--
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Other Books</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p>
        </div>
      </div>
    </div>
  </div>

  
  <div class="container-fluid mb-md-5">
    <div class="row">
      <div class="col-md-12 mt-4 pt-2">
        <owl-carousel-o id="six-slide" [options]="customOptions" class="owl-stage-outer">
          <ng-container *ngFor="let slide of bookList;let i=index">
            <ng-template carouselSlide>
              <div class="card border-0 work-container work-primary work-grid position-relative d-block overflow-hidden mx-2">
                <div class="card-body p-0">
                  <a (click)="open(i)" class="d-inline-block" title="">
                    <img [src]="slide.image" class="img-fluid shadow rounded" alt="work-image">
                  </a>
                  <div class="content p-3">
                    <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark title">Iphone mockup</a></h5>
                    <h6 class="text-muted tag mb-0">$ 16</h6>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>-->

</section>
<!--end section-->
<!-- End -->
<!-- Subscribe Start -->

<!--
<section class="section bg-primary mt-md-5">
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="app-subscribe text-center text-md-left">
          <img src="assets/images/book/6.jpg" class="img-fluid rounded-md shadow-md" alt="">
        </div>
      </div>

      <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title text-center text-md-start">
          <h1 class="fw-bold text-white title-dark mb-3">Upcoming Book</h1>
          <p class="text-white-50 para-dark">Start working with Landrick that can provide everything you need to
            generate awareness, drive traffic, connect.</p>

          <div class="mt-4 pt-2">
            <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-warning">Pre-order Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
-->
<!--end section-->
<!-- Subscribe End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Invoice Start -->
<section class="bg-invoice bg-light" id="home">
  <div class="container">
    <div class="row mt-5 pt-4 pt-sm-0 justify-content-center">
      <div class="col-lg-10">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <div class="invoice-top pb-4 border-bottom">
              <div class="row">
                <div class="col-md-8">
                  <div class="logo-invoice mb-2">TaleWeaver IA<span class="text-primary">.</span></div>
                  <a href="javascript:void(0)" class="text-primary h6"><i-feather name="link"
                      class="fea icon-sm text-muted me-2"></i-feather>www.taleweaveria.com</a>
                </div>
                <!--end col-->

               <!-- <div class="col-md-4 mt-4 mt-sm-0">
                  <h5>Address :</h5>
                  <dl class="row mb-0">
                    <dt class="col-2 text-muted">
                      <i-feather name="map-pin" class="fea icon-sm"></i-feather>
                    </dt>
                    <dd class="col-10 text-muted">
                      <a href="javascript:void(0);" class="video-play-icon text-muted">
                        <p class="mb-0">1419 Riverwood Drive,</p>
                        <p class="mb-0">Redding, CA 96001</p>
                      </a>
                    </dd>

                    <dt class="col-2 text-muted"><i class="uil uil-envelope"></i></dt>
                    <dd class="col-10 text-muted">
                      <a href="mailto:contact@example.com" class="text-muted">info@landrick.com</a>
                    </dd>

                    <dt class="col-2 text-muted">
                      <i-feather name="phone" class="fea icon-sm"></i-feather>
                    </dt>
                    <dd class="col-10 text-muted">
                      <a href="tel:+152534-468-854" class="text-muted">(+12) 1546-456-856</a>
                    </dd>
                  </dl>
                </div>-->
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="invoice-middle py-4">
              <h5>Detalle de Compra :</h5>
              <div class="row mb-0">
                <div class="col-md-8 order-2 order-md-1">
                  <dl class="row">
                    <dt class="col-md-3 col-5 fw-normal">Recibo No. :</dt>
                    <dd class="col-md-9 col-7 text-muted"> {{ data.id }} </dd>

                    <dt class="col-md-3 col-5 fw-normal">Nombre :</dt>
                    <dd class="col-md-9 col-7 text-muted">{{ data.name }}</dd>

                    <dt class="col-md-3 col-5 fw-normal">Estado :</dt>
                    <dd class="col-md-9 col-7 text-muted">
                      <p class="mb-0">{{ data.status }}</p>
                    </dd>
                    <!--
                    <dt class="col-md-3 col-5 fw-normal">Phone :</dt>
                    <dd class="col-md-9 col-7 text-muted">(+45) 4584-458-695</dd>-->
                  </dl>
                </div>

                <div class="col-md-4 order-md-2 order-1 mt-2 mt-sm-0">
                  <dl class="row mb-0">
                    <dt class="col-md-4 col-5 fw-normal">Fecha Compra :</dt>
                    <dd class="col-md-8 col-7 text-muted"> {{ data.date_created | date:"dd-MM-yyyy" }} </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="invoice-table pb-4">
              <div class="table-responsive bg-white shadow rounded">
                <table class="table mb-0 table-center invoice-tb">
                  <thead class="bg-light">
                    <tr>
                      <th scope="col" class="border-bottom text-start">No.</th>
                      <th scope="col" class="border-bottom text-start">Producto</th>
                      <th scope="col" class="border-bottom">Cantidad</th>
                      <th scope="col" class="border-bottom">USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" class="text-start">1</th>
                      <td class="text-start"> {{ data.description }} </td>
                      <td> 1 </td>
                      <td>$ {{ data.cost }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-5 ms-auto">
                  <ul class="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                    <li class="d-flex justify-content-between">USD Total :<span>$ {{ data.total_cost }}</span></li>
                  </ul>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="invoice-footer border-top pt-4">
              <div class="row">
                <div class="col-sm-6">
                  <div class="text-sm-start text-muted text-center">
                    <h6 class="mb-0">Servicio de ayuda : <a href="tel:+152534-468-854" class="text-warning">contact@condonacion_cae.cl</a></h6>
                  </div>
                </div>

                <!--<div class="col-sm-6">
                  <div class="text-sm-end text-muted text-center">
                    <h6 class="mb-0"><a routerLink="/page-terms" target="_blank" class="text-primary">Terms &
                        Conditions</a></h6>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Invoice End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { Component, inject } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.css']
})
export class RecaptchaComponent {

  recaptchaService = inject(ReCaptchaV3Service);

  executeRecaptcha() {
    this.recaptchaService.execute('').subscribe((token) => {
      console.log(token)
    })
  }
}

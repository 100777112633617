<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h2> CONDONACION CAE </h2>
          <ul class="list-unstyled mt-4 mb-0">
          </ul>
        </div>
      </div><!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->





<!-- Blog STart -->
<section class="section">
  <div class="container">
    <div class="row d-grid d-md-flex">
      

      <!-- BLog Start -->
      <div class="col-lg-8 col-md-6 order-md-1"
      [ngClass]="{'order-1': !isMobile, 'order-2': isMobile}">
        <div class="card blog blog-detail border-0 shadow rounded">
          <img src="assets/images/condonacioncae.jpg" class="img-fluid rounded-top" alt="">
          <div class="card-body content">
            <h6>
              <i class="mdi mdi-tag text-primary me-1"></i>
              <a href="javscript:void(0)" class="text-primary">Fin al CAE: a quiénes beneficiará la condonación en el proyecto del Gobierno de Boric</a>
            </h6>
            <p class="text-muted mt-3">
            El presidente Gabriel Boric presentó un proyecto de ley que busca reemplazar el Crédito con Aval del Estado (CAE), un sistema implementado en 2006 que ha generado deudas para más de 1.200.000 personas, con un nuevo mecanismo de financiamiento para la educación superior. Esta iniciativa, que será enviada al Congreso para su discusión, también incluye la posibilidad de reorganizar las deudas y otorgar condonaciones parciales o totales en ciertos casos.<br><br>

            Este proyecto responde a un compromiso asumido por el Gobierno antes de asumir el mandato. Durante su etapa como líder estudiantil, Boric abogó por la eliminación del sistema de crédito estudiantil, pero reconoce que la propuesta actual ha sido adaptada:<br><br>
            
            <blockquote class="blockquote mt-3 p-3">
              <p class="text-muted mb-0 fst-italic">“Lo hacemos conscientes que el proyecto, tal como lo imaginábamos, ha ido sufriendo modificaciones y nos alegramos que así sea. Hay algunos quienes dicen ‘esto no es lo que ustedes dijeron en un comienzo’. Bueno, pero si las cosas cambian justamente es porque uno es capaz de incorporar argumentos de otras personas”</p>
            </blockquote>
            <p class="text-muted">, señaló el presidente en un acto en la Universidad de Santiago de Chile.<br><br>
            </p>
            <p class="text-muted mt-3">
              <b>¿Qué es el FES?</b> <br>
            El Ministerio de Educación ha informado que la iniciativa propone un nuevo instrumento de financiamiento público, denominado FES (Financiamiento Estatal Solidario). Este sistema dejará fuera a los bancos, que hasta ahora operaban como prestamistas bajo el aval del Estado. Según las autoridades, el FES no es un crédito, sino un modelo de "retribución", que comenzará a aplicarse un año después de que el estudiante egrese o interrumpa sus estudios, y ya se encuentre trabajando.<br><br>
            
            El monto de la retribución será proporcional al sueldo, con un máximo del 8% mensual, y se establecerá un tramo exento de 500.000 pesos mensuales. Además, la obligación de contribuir al sistema estará limitada a un máximo de 20 años, o a un período de dos años por cada semestre financiado con el FES.<br><br>
            
            <b>Condonación de deuda</b> <br>
            La propuesta descarta la condonación total del CAE debido a la falta de apoyo político en el Congreso, optando en su lugar por un modelo de reorganización y condonación parcial para los deudores. Las deudas podrán ser reorganizadas de forma voluntaria a través de tres mecanismos:<br><br>
            
            <b>Condonación inicial:</b> Se evaluarán factores como el estado académico del estudiante (si egresó o no), su situación de pago (al día o en mora), y el porcentaje de cuotas pagadas en relación al total.<br>
            <b>Condonación por pago anticipado:</b> Aquellos que paguen el 75% del saldo restante tras la condonación inicial podrán condonar el resto de su deuda.<br>
            <b>Condonación progresiva:</b> Las cuotas restantes, tras la condonación inicial, serán recalculadas para que no superen el 8% del ingreso mensual del deudor, con un tramo exento de 500.000 pesos.<br><br>
            
            <b>Beneficios tributarios para quienes ya pagaron</b> <br>
            Quienes lograron pagar completamente su deuda con el CAE podrán acceder a beneficios tributarios. Esto incluye descuentos en el Impuesto Global Complementario o en el Impuesto de Segunda Categoría, equivalentes a cuatro unidades de fomento (UF) anuales para egresados y seis UF anuales para quienes no concluyeron sus estudios. Este beneficio tendrá una duración de 20 años.<br><br>
            
            <b>Quiénes serán beneficiados</b> <br>
            El proyecto no solo se aplicará a deudores del CAE, sino también a quienes accedieron a la educación superior mediante el Fondo Solidario o el Crédito Corfo. Estas modalidades de financiamiento estudiantil también serán contempladas dentro de la reorganización de deudas.<br><br>
          
            <div class="post-meta mt-3">
              <ul class="list-unstyled mb-0">
                <!--<li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                      class="uil uil-heart me-1"></i>33</a></li>-->
                <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                      class="uil uil-comment me-1"></i> {{ comments.length }} </a></li>
              </ul>
            </div>
          </div>
        </div>
      
        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Comentarios :</h5>

            <ul class="media-list list-unstyled mb-0">
              <li class="mt-4" *ngFor="let comment of comments">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/icon/chatbot.png" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading"> {{ comment.name }} </a>
                      </h6>
                      <small class="text-muted">{{ formatDate(comment.created_at) }}</small>
                    </div>
                  </div>
                  <!--<a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>-->
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" {{ comment.description }} "</p>
                </div>
              </li>

              <!--<li class="mt-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                      </h6>
                      <small class="text-muted">15th August, 2019 at 05:44 pm</small>
                    </div>
                  </div>
                  <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                    Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                </div>
              </li>-->

              <!--<li class="mt-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <a class="pe-3" href="javascript:void(0)">
                      <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                        alt="img">
                    </a>
                    <div class="commentor-detail">
                      <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                      </h6>
                      <small class="text-muted">16th August, 2019 at 03:44 pm</small>
                    </div>
                  </div>
                  <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
                <div class="mt-3">
                  <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                    Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                </div>

                <ul class="list-unstyled ps-4 ps-md-5 sub-comment">
                  <li class="mt-4">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <a class="pe-3" href="javascript:void(0)">
                          <img src="assets/images/client/01.jpg"
                            class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                        </a>
                        <div class="commentor-detail">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo
                              Peterson</a></h6>
                          <small class="text-muted">17th August, 2019 at 01:25 pm</small>
                        </div>
                      </div>
                      <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                    </div>
                    <div class="mt-3">
                      <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of
                        Lorem Ipsum available, but the majority have suffered alteration in some form, by injected
                        humour "</p>
                    </div>
                  </li>
                </ul>
              </li>-->
            </ul>
          </div>
        </div>

        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body">
            <h5 class="card-title mb-0">Deja un comentario :</h5>

            <form [formGroup]="commentForm" class="mt-3" (ngSubmit)="onResolveCaptcha('submitForm')">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Tu Comentario</label><br>
                    <p class="text-muted">Solo se visualizará tu Nombre y Comentario.</p>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea id="message"  rows="5" name="message"
                        class="form-control ps-5" required="" formControlName="description"></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Nombre <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input id="name" name="name" type="text" placeholder="Nombre" class="form-control ps-5" required="" formControlName="name">
                      <div *ngIf="commentForm.get('name')?.invalid && commentForm.get('name')?.touched">
                        <small *ngIf="commentForm.get('name')?.hasError('required')" class="text-danger">El nombre es obligatorio.</small>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-6">
                  <div class="mb-3">
                    <label class="form-label">Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input 
                        id="email" 
                        type="email" 
                        placeholder="Email" 
                        name="email" 
                        class="form-control ps-5" 
                        formControlName="email"
                        required
                      />
                      <div *ngIf="commentForm.get('email')?.invalid && commentForm.get('email')?.touched">
                        <small *ngIf="commentForm.get('email')?.hasError('required')" class="text-danger">El email es obligatorio.</small>
                        <small *ngIf="commentForm.get('email')?.hasError('email')" class="text-danger">Ingresa un email válido.</small>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <input type="hidden" formControlName="recaptcha" />

                <div class="col-md-12">
                  <div class="send d-grid">
                    <button type="submit" class="btn btn-primary">Enviar Comentario</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            <!--end form-->
          </div>
        </div>
      </div>
      <!-- BLog End -->

      <!-- START SIDEBAR -->
      <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
        [ngClass]="{'order-1': isMobile, 'order-2': !isMobile}">
        <div class="card border-0 sidebar sticky-bar ms-lg-4">
            <div class="card-body p-0">
              <!-- Calculadora -->
              <div>
                <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                    Calculadora CAE
                </span>
                <div>
                  <cae-calc></cae-calc>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- END SIDEBAR -->

    </div>
  </div>
</section>
<!--End section-->

<!-- Back to top -->
<!--<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>-->
<!-- Back to top -->
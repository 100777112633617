import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { LoadingService } from '../shared/loading/loading.service';

export interface ProfileGoogle {
  email: string;
  id: string;
  name: string;
  picture?: string;
}


export enum EnumAuthProvidersID {
  google = 'google.com',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  credentialData: any;
  userData: any;
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
   constructor(
    public router: Router,
  ) {
      localStorage.setItem('user', 'null');
  }

    /**
   * Setter & getter for access token
   */
    get accessToken(): string {
      return localStorage.getItem('accessToken') ?? '';
    }
    set accessToken(token: string) {
      localStorage.setItem('accessToken', token);
    }
  

    /**
     * Sign in using the access token
     */
    //signInUsingToken(): Observable<any> {
    signInUsingToken(name=null, email=null, redirect_to=null): Observable<any> {
      // Set the authenticated flag to true
      this._authenticated = true;
      return of(true);
    }
    
    /**
     * Sign out
     */
    signOut(): Observable<any> {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      this._authenticated = false;
      return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
      // Check if the user is logged in
      if (this._authenticated) {
        return of(true);
      }

      // Check the access token availability
      if (!this.accessToken) {
        this._authenticated = false;
        return of(false);
      }

      // Check the access token expire date
      //if (AuthUtils.isTokenExpired(this.accessToken)) {
      //return of(false);
      //}

      // If the access token exists and it didn't expire, sign in using it
      return this.signInUsingToken();
    }
  
}

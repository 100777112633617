import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient) { }


  create(name, email) {
    const data = {name: name, email: email}
    const url = `${environment.api}/create-user`
    return this.http.post(url, data).pipe(map((x: any) => x));
  }

  createComment(payload) {
    const url = `${environment.api}${environment.module_context.core.public}/create-comments`
    return this.http.post(url, payload).pipe(map((x: any) => x));
  }

  getComments() {
    const url = `${environment.api}${environment.module_context.core.public}/get-comments`
    return this.http.get(url).pipe(map((x: any) => x));
  }


  miIndicadorIntegration() {
    const url = `${environment.api}${environment.module_context.core.public}/currency-money`
    return this.http.get(url).pipe(map((x: any) => x));
  }

}
